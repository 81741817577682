import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useSelector, useToast } from 'hooks';
import moment from 'moment';
import { useEffect, useState, FC } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { dashboardsSelectors } from 'store/entities/dashboards';
import { get7DaysAgoDate } from 'utils/getSevenDayAgoDate';

interface Props {
  // initialSettings: any;
  onChange: (startDate: Date, endDate: Date, compareStartDate: Date, compEndDate: Date, compareValue: string) => void;

  showCompareTo?: boolean;
}

export const RangePicker: FC<Props> = ({ onChange, showCompareTo = false }) => {
  const { errorToast } = useToast();
  const date = useSelector(dashboardsSelectors.date);
  const endDate = useSelector(dashboardsSelectors.endDate);
  const compareStartDate = useSelector(dashboardsSelectors.compareStartDate);
  const compareEndDate = useSelector(dashboardsSelectors.compareEndDate);

  const sevenDaysAgoDate: Date = get7DaysAgoDate();

  const [range, setRangeName] = useState('');
  const [selectedCompareValue, setSelectedCompareValue] = useState<string>('preceding-period'); // Set default value here

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: date, // sevenDaysAgoDate,
    endDate: endDate, //new Date(),
  });

  const [selectedCompareDateRange, setSelectedCompareDateRange] = useState({
    startDate: compareStartDate, //  moment(sevenDaysAgoDate).subtract(1, 'month').toDate(),
    endDate: compareEndDate, //moment(new Date()).subtract(1, 'month').toDate(),
  });

  const handleApply = (event: any, picker: any) => {
    const rangeName = picker.chosenLabel;
    const st = new Date(picker.startDate._d);
    const end = new Date(picker.endDate._d);
    
    //Verify start date is not after end date, if so show error
    if(st> end)
      errorToast('Start date is after end date!');

    setSelectedDateRange({ startDate: st, endDate: end });

    setRangeName(rangeName);
    //if (showCompareTo) {
    if (selectedCompareValue === 'preceding-period') {
      let newStartDate = new Date();
      let newEndDate = new Date();
      if (rangeName == 'This Week') {
        newStartDate = moment(st).subtract(1, 'week').toDate();
        newEndDate = moment(end).subtract(1, 'week').toDate();
      } else if (rangeName == 'This Month') {
        newStartDate = moment(st).subtract(1, 'month').toDate();
        newEndDate = moment(end).subtract(1, 'month').toDate();
      } else if (rangeName == 'This Quarter') {
        newStartDate = moment(st).subtract(1, 'quarter').toDate();
        newEndDate = moment(end).subtract(1, 'quarter').toDate();
      } else if (rangeName == 'This Year') {
        newStartDate = moment(st).subtract(1, 'year').toDate();
        newEndDate = moment(end).subtract(1, 'year').toDate();
      } else if (rangeName == 'Custom Range') {
        const startDate = moment(st);
        const endDate = moment(end);
        const differenceInDays = endDate.diff(startDate, 'days');

        const diffDate = moment(end.getDate()).subtract(st.getDate()).toDate();
        newStartDate = moment(st)
          .subtract(differenceInDays + 1, 'day')
          .toDate();
        newEndDate = moment(st).subtract(1, 'day').toDate();
      }
      setSelectedCompareDateRange({ startDate: newStartDate, endDate: newEndDate });
      onChange(st, end, newStartDate, newEndDate, selectedCompareValue);
      return;
    }
    if (selectedCompareValue === 'same-period-last-year') {
      const newStartDate = moment(st).subtract(1, 'year').toDate();
      const newEndDate = moment(end).subtract(1, 'year').toDate();
      setSelectedCompareDateRange({ startDate: newStartDate, endDate: newEndDate });
      onChange(st, end, newStartDate, newEndDate, selectedCompareValue);
      return;
    }
    //}
    onChange(st, end, selectedCompareDateRange.startDate, selectedCompareDateRange.endDate, selectedCompareValue);
  };

  const handleCompareChange = (event: SelectChangeEvent<string>) => {
    setSelectedCompareValue(event.target.value);
    if (event.target.value === 'preceding-period') {
      // const newStartDate = moment(selectedDateRange.startDate).subtract(1, 'month').toDate();
      // const newEndDate = moment(selectedDateRange.endDate).subtract(1, 'month').toDate();
      // setSelectedCompareDateRange({ startDate: newStartDate, endDate: newEndDate });

      let newStartDate = new Date();
      let newEndDate = new Date();
      if (range == 'This Week') {
        newStartDate = moment(selectedDateRange.startDate).subtract(1, 'week').toDate();
        newEndDate = moment(selectedDateRange.endDate).subtract(1, 'week').toDate();
      } else if (range == 'This Month') {
        newStartDate = moment(selectedDateRange.startDate).subtract(1, 'month').toDate();
        newEndDate = moment(selectedDateRange.endDate).subtract(1, 'month').toDate();
      } else if (range == 'This Quarter') {
        newStartDate = moment(selectedDateRange.startDate).subtract(1, 'quarter').toDate();
        newEndDate = moment(selectedDateRange.endDate).subtract(1, 'quarter').toDate();
      } else if (range == 'This Year') {
        newStartDate = moment(selectedDateRange.startDate).subtract(1, 'year').toDate();
        newEndDate = moment(selectedDateRange.endDate).subtract(1, 'year').toDate();
      } else if (range == 'Custom Range') {
        const startDate = moment(selectedDateRange.startDate);
        const endDate = moment(selectedDateRange.endDate);
        const differenceInDays = endDate.diff(startDate, 'days');

        const diffDate = moment(selectedDateRange.endDate.getDate())
          .subtract(selectedDateRange.startDate.getDate())
          .toDate();
        newStartDate = moment(selectedDateRange.startDate)
          .subtract(differenceInDays + 1, 'day')
          .toDate();
        newEndDate = moment(selectedDateRange.startDate).subtract(1, 'day').toDate();
      }
      setSelectedCompareDateRange({ startDate: newStartDate, endDate: newEndDate });
      onChange(
        selectedDateRange.startDate,
        selectedDateRange.startDate,
        newStartDate,
        newEndDate,
        selectedCompareValue,
      );
      return;
    }
    if (event.target.value === 'same-period-last-year') {
      const newStartDate = moment(selectedDateRange.startDate).subtract(1, 'year').toDate();
      const newEndDate = moment(selectedDateRange.endDate).subtract(1, 'year').toDate();
      setSelectedCompareDateRange({ startDate: newStartDate, endDate: newEndDate });
      onChange(selectedDateRange.startDate, selectedDateRange.endDate, newStartDate, newEndDate, selectedCompareValue);
      return;
    }
  };
  return (
    <Grid container spacing={1} direction="row" display="flex" justifyContent="flex-end"  alignItems="flex-end">
      <Grid
        item
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end'}}
      >
        <DateRangePicker
          onApply={handleApply}
          initialSettings={{
            autoApply: true,
            startDate: selectedDateRange.startDate,
            endDate: selectedDateRange.endDate,
            alwaysShowCalendars: true,
            ranges: {
              'This Week': [moment().utc().startOf('week').toDate(), moment(new Date()).utc().toDate()],
              'This Month': [
                moment().utc().startOf('month').toDate(),
                moment(new Date()).utc().toDate(),
              ],
              'This Quarter': [
                moment().utc().startOf('quarter').toDate(),
                moment(new Date()).utc().toDate(),
              ],
              'This Year': [moment().utc().startOf('year').toDate(), moment(new Date()).utc().toDate()],
            },
          }}
        >
          {/* <Button>Date Range Selector</Button> */}
          <FormControl>
            <TextField
              label="Date Range"
              style={{ width: '100%' }}
              value={
                moment(selectedDateRange.startDate).format('DD/MM/YYYY') +
                '-' +
                moment(selectedDateRange.endDate).format('DD/MM/YYYY')
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarMonthIcon style={{ cursor: 'pointer' }} />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </DateRangePicker>
      </Grid>

      {showCompareTo ? (
        <>
          <Grid
            item
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end'}}
          >
            <FormControl>
              <InputLabel
                id="dropdown-label"
                style={{ backgroundColor: '#ffffff', paddingLeft: '.5em', paddingRight: '.5em' }}
              >
                Compare To
              </InputLabel>
              <Select
                style={{ width: '100%' }}
                labelId="dropdown-label"
                id="dropdown"
                value={selectedCompareValue}
                onChange={handleCompareChange}
              >
                <MenuItem value="preceding-period">Preceding Period</MenuItem>
                <MenuItem value="same-period-last-year">Same period last year</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item            
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}
          >
            <label style={{ fontSize: '12px', textAlign: 'left' }}>
              <b>Date Range:</b>
              <br />
              {moment(selectedDateRange.startDate).format('DD MMM YYYY')}
              {' - '}
              {moment(selectedDateRange.endDate).format('DD MMM YYYY')}
            </label>
            <label style={{ fontSize: '12px', textAlign: 'left' }}>
              <b>Compare To:</b>
              <br />
              {moment(selectedCompareDateRange.startDate).format('DD MMM YYYY')}
              {' - '}
              {moment(selectedCompareDateRange.endDate).format('DD MMM YYYY')}
            </label>
          </Grid>
        </>
      ) : (
        ''
      )}
    </Grid>
  );
};
