import { Box, MenuItem, Select } from '@mui/material';
import { FC } from 'react';

interface Props {
  data: any;
}

export const HeaderDropdown : FC<Props> = ({ data }) => {
  return (
    <Box sx={{ mr: 1, mt: 1, marginTop:'-5px' }}>
      <Select
        value={0}
        sx={{
          width: '100%',
          textAlign: 'right',
          backgroundColor: '#fff',
          borderRadius: '15px',
        }}
      >
  {/* <MenuItem key={index} value={index}>{item.dailyLoads[index].systemName}{item.dailyLoads[index].lastSuccessRunDT!=null? new Date(item.dailyLoads[index].lastSuccessRunDT).toUTCString():''}</MenuItem> */}
  <MenuItem value={0}>Data Last Refreshed:</MenuItem> 
{data.dailyLoads.map((item:any) => (
        <MenuItem key={item.systemName} value={item.systemName}>{item.systemName}:<br />{item.lastSuccessRunDT!=null? new Date(item.lastSuccessRunDT).toUTCString():''}</MenuItem> 

      ))}
       
      </Select>
      <Box sx={{ mx: 0.5 }} component="span" />
    </Box>
    
  );
};
