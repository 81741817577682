import { Box, Typography, TableCell } from '@mui/material';
import { FC } from 'react';

import { compareTableStyles as S } from './styles';

type Sign = 'currency' | 'percentage';

interface Props {
  currencyLabel?:string;
  label: number | string;
  value: number | string;
  progress?: number;
  sign?: Sign;
  withProgress?: boolean;
  sx?:object
}

export const CompareTableProgressCell: FC<Props> = ({currencyLabel = '', label, value, progress = 0, sign, withProgress = false, sx }) => (
  <TableCell align="center" sx={sx}>
    <Box
      position="relative"
      display="flex"
      flexDirection="row"
      alignItems="center"
      minHeight={50}
      justifyContent="space-between"
      margin="0"
    >
      {withProgress ? (
        <S.ProgressGroup flexGrow="1" border="1" minWidth={170}>
          <S.ProgressBar width={`${progress}%`} />
          <S.ProgressLabel variant="subtitle1">
            {sign === 'currency' && currencyLabel}
            {value}
            {sign === 'percentage' && '%'}
          </S.ProgressLabel>
        </S.ProgressGroup>
      ) : (
        <Typography variant="subtitle1" marginRight="1px" fontWeight="700" minWidth={20}>
          {sign === 'currency' && currencyLabel}
          {value}
          {sign === 'percentage' && '%'}
        </Typography>
      )}

      {label ? (
        <Box width="30%">
          <Typography color={theme => (+label >= 0 ? theme.colors.success.main : theme.colors.error.main)}>
            {label}
            {isNaN(+label) ? '' : '%'}
          </Typography>
        </Box>
      ) : (
        ''
      )}
    </Box>
  </TableCell>
);
