import { Container, Grid, Typography, Box, CircularProgress, Card, Divider, Breadcrumbs, Button } from '@mui/material';
import { BarChart, BarLineChart, DoughnutChart, LineChart } from 'components/charts';
import { EmptyState } from 'components/empty-state';
import { Heading } from 'components/heading';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'hooks';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { NavLink, useSearchParams } from 'react-router-dom';
import { Cell, Pie, PieChart, Tooltip, Bar, XAxis, CartesianGrid } from 'recharts';
import { routes } from 'router/routes';
import { dashboardsSelectors, dashboardsSlice } from 'store/entities/dashboards';
import { getBrands, getMarkets, marketingAnalyzerSelectors } from 'store/entities/marketing-analyzer';
import { revenueSpendSelectors } from 'store/entities/revenue-spend';
import { TiktokLeadTableData } from 'types/Tiktok/Tiktok';
import { formatCamelCase } from 'utils/formatCamelCase';

import { TiktokLeadTable } from './component/dashboard-table/tiktok-dashboard-table';
import { TiktokFiltersClicks } from './component/tiktok-filters-clicks';
import { currencyLabel } from 'utils/numbers';
import { userInfo } from 'os';


export default function TiktokClickDashboard() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const brands = useSelector(marketingAnalyzerSelectors.brands);
  const markets = useSelector(marketingAnalyzerSelectors.markets);
  const date = useSelector(dashboardsSelectors.date);
  const marketsValue = useSelector(dashboardsSelectors.markets);

  const allChannels = () => brands.filter(brand => brand.name !== 'All').map(brand => brand.id);
  const initialFilters = useSelector(revenueSpendSelectors.filters);
 
  const currencyLabel = '';

  //Filter Handles
  const countryFilterValue = useSelector(state => state.filtersDataSlice.country);
  const cityFilterValue = useSelector(state => state.filtersDataSlice.city);
  const storeFilterValue = useSelector(state => state.filtersDataSlice.store);
  const filterChangesApplied = useSelector(state => state.filtersDataSlice.filterChangesApplied);
  
  const COLORS = ['#6554ff', '#D3D3D3', '#FFBB28', '#FF8042'];

  const click = formatCamelCase(window.location.pathname.split('/').at(-1)).slice(0).replace(/%20/g, ' ');
  const tiktokdata = useSelector(state => state.tiktokDashboard.tiktokdashboardData);

  const loading = useSelector(state => state.tiktokDashboard.tiktokdashboardLoading);

  const isstack = true;

  const best10Campaigns: TiktokLeadTableData[] = null;

  const mapgendgerByAge = () => {
    const genderAge = getGenderbyAge();
    const output: any[] = [];
    let age_ranges: any[] = [];

    //Gather age ranges
    genderAge.forEach(element => {
      age_ranges.push(element.age_range);
    });

    //Remove duplicates
    age_ranges = age_ranges.filter((value, index, array) => array.indexOf(value) === index);

    //Refine byGenderAge Data
    age_ranges.forEach(element => {
      let male_value = '';
      let female_value = '';
      let age_value = '';

      genderAge.forEach(elem2 => {
        if (elem2.age_range === element) {
          age_value = element;
          if (elem2.gender === 'male') {
            male_value = elem2.value.toString();
          } else if (elem2.gender === 'female') {
            female_value = elem2.value.toString();
          }
        }
      });
      output.push({ age_range: age_value, female: female_value, male: male_value });
    });
    return output;
  };

  const getGenderbyAge = () => {
    let result: any[] = [];

    if(tiktokdata==null) return [];
    switch (click.toString().trim().toLocaleLowerCase()) {
      case 'ad spend':
        result = tiktokdata?.audience[0].ad_cost.byGenderAge;

        break;
      case 'clicks':
        result = tiktokdata?.audience[0].clicks.byGenderAge;

        break;
      case 'ecommerce value':
        result = tiktokdata?.audience[0].ecommerce_value.byGenderAge;

        break;
      case 'impressions':
        result = tiktokdata?.audience[0].impressions.byGenderAge;

        break;
      case 'conversions':
        result = tiktokdata?.audience[0].conversions.byGenderAge;

        break;
    }
    return result;
  };

  const getDailyclickbyGender = () => {
    let result: any[] = [];
    if(tiktokdata==null) return [];


    switch (click.toString().trim().toLocaleLowerCase()) {
      case 'ad spend':
        result = tiktokdata?.audience[0].ad_cost.dailyByGender;

        break;
      case 'clicks':
        result  = tiktokdata?.audience[0].clicks.dailyByGender;

        break;
      case 'ecommerce value':
        result =tiktokdata?.audience[0].ecommerce_value.dailyByGender;

        break;
      case 'impressions':
        result = tiktokdata?.audience[0].impressions.dailyByGender;

        break;
      case 'conversions':
        result =tiktokdata?.audience[0].conversions.dailyByGender;

        break;
    }
    return result;
  };
  const getClickbygender = () => {
    let result: any[] = [];
    if(tiktokdata==null) return [];


    switch (click.toString().trim().toLocaleLowerCase()) {
      case 'ad%20 spend':
        result = tiktokdata?.audience[0].ad_cost.byGender;

        break;
      case 'clicks':
        result = tiktokdata?.audience[0].clicks.byGender;

        break;
      case 'ecommerce%20value':
        result = tiktokdata?.audience[0].ecommerce_value.byGender;

        break;
      case 'impressions':
        result = tiktokdata?.audience[0].impressions.byGender;

        break;
      case 'conversions':
        result = tiktokdata?.audience[0].conversions.byGender;

        break;
    }
    return result;
  };

  const form = useFormik({
    initialValues: {
      ...initialFilters,
      date,
      markets: marketsValue,
    },
    onSubmit: values => {
      const isAllChannels = brands.find(brand => brand.id === values.channel).name === 'All';
      const channel = isAllChannels ? allChannels() : values.channel;

      //   dispatch(
      //     revenueSpendSlice.actions.setFilters({
      //       channel: values.channel,
      //     }),
      //   );
    },
  });

  const { values, setFieldValue } = form;

  const getmappedClikcwithgenderSource = () => {
    if (getClickbygender()) {
      return getClickbygender().map(a => ({ name: a.gender, value: a.value }));
    }
    return [];
  };

  const getmappedClikcwithageSource = () => {
    if (getGenderbyAge()) {
      const fcount = getGenderbyAge()
        .filter(a => a.gender == 'female')
        .reduce((n, { value }) => n + value, 0);

      const mcount = getGenderbyAge()
        .filter(a => a.gender == 'male')
        .reduce((n, { value }) => n + value, 0);
      const array = [];
      array.push({ name: 'female', value: fcount });
      array.push({ name: 'male', value: mcount });
      return array;
      // return byGenderAge.map(a => ({ name: a.gender, value: a.value }));
    }
    return [];
  };

  const sortedCLickgenderByDate = () => {
    if (!getDailyclickbyGender()) {
      return [];
    }

    return getDailyclickbyGender()
      ? [...getDailyclickbyGender()].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      : [];
  };

  useEffect(() => {
    dispatch(getMarkets());
    dispatch(getBrands());
  }, [dispatch]);

  useEffect(() => {
    dispatch(dashboardsSlice.actions.setDashboardsDate(values.date));
  }, [values.date]);

  useEffect(() => {
    dispatch(dashboardsSlice.actions.setDashboardsMarkets(values.markets));
  }, [values.markets]);

  useEffect(() => {
    if (brands.length > 0 && !initialFilters.channel) {
      setFieldValue('channel', brands.map(brand => brand.id)[0]);
    }
  }, [brands, setFieldValue]);

  useEffect(() => {
    if (markets && !marketsValue.length) {
      setFieldValue(
        'markets',
        markets.filter(market => market.name === 'All'),
      );
    }
  }, [markets]);

  return (
    <>
      <Helmet>
        <title>TikTok Clicks Drill Down</title>
      </Helmet>

      <FormikProvider value={form}>
        <Container maxWidth="xl">
          <Grid container direction="row" justifyContent="start" alignItems="stretch" spacing={2}>
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Button variant="text" component={NavLink as any} to={routes.tiktokDashboard.url}>
                  TickTok Dashboard
                </Button>

                <Typography color="text.primary" p="16px">
                  {click}
                </Typography>
              </Breadcrumbs>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h1">TikTok Clicks Drill Down</Typography>
            </Grid>

            <Grid item lg={12} xs={12}>
              <TiktokFiltersClicks onClick={function (name: string): void {
              } } />
            </Grid>

            <Grid item xs={12}>
              <Heading>BEST 10 PERFORMING CAMPAIGNS</Heading>
            </Grid>
            {best10Campaigns ? (
              <Grid item lg={12} xs={12}>
                <Card>
                  {best10Campaigns.length > 0 ? (
                    <TiktokLeadTable
                      currencyLabel='---'
                      columns={['Campaign', 'Ad Spend', 'Conversion', 'Video Views', 'Video Play actions ', 'Comments']}
                      data={best10Campaigns ?? []}
                    />
                  ) : (
                    <EmptyState> There is no campaign date</EmptyState>
                  )}
                </Card>
              </Grid>
            ) : (
              <Grid item lg={12} xs={12}>
                <Card>
                  <EmptyState>There is no campaign date</EmptyState>
                </Card>
              </Grid>
            )}

            <Grid item lg={12} xs={12}>
              <TiktokFiltersClicks
                onClick={function (name: string): void {
                  throw new Error('Function not implemented.');
                }}
              />
            </Grid>

            <Grid container item lg={4} xs={12}>
              <Card style={{ width: '100%', height: '100%' }}>
                <Box p={2.5} display="flex" flexDirection="column">
                  <Typography gutterBottom variant="h4">
                    {click} by Gender
                  </Typography>
                </Box>
                <Divider />

                <DoughnutChart
                  data={getmappedClikcwithgenderSource() || []}
                  dimensions={{ height: 350, width: '100%' }}
                  tooltipBeforeSymbol={currencyLabel}
                />
              </Card>
            </Grid>

            <Grid item xs={8} lg={8}>
              <Card sx={{ height: '100%' }}>
                <Box p={2.5}>
                  <Typography variant="h4">Daily {click} by Gender</Typography>
                </Box>
                <Divider />

                <LineChart
                  data={sortedCLickgenderByDate()}
                  xDataKey="date"
                  yDataKey="value"
                  groupBy="gender"
                  dimensions={{ height: 500, width: '100%' }}
                  formatDate
                  showDate
                />
              </Card>
            </Grid>

            <Grid container item lg={4} xs={12}>
              <Card style={{ width: '100%', height: '100%' }}>
                <Box p={2.5} display="flex" flexDirection="column">
                  <Typography gutterBottom variant="h4">
                    {click} by Age
                  </Typography>
                </Box>
                <Divider />

                <PieChart width={480} height={400}>
                  <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={getmappedClikcwithageSource()}
                    cx="50%"
                    cy="50%"
                    outerRadius={120}
                    fill="#8884d8"
                    label
                  >
                    {getmappedClikcwithageSource().map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  {/* <Pie dataKey='value' data={data02} cx={500} cy={200} innerRadius={40} outerRadius={80} fill='#82ca9d' /> */}
                  <Tooltip />
                </PieChart>
              </Card>
            </Grid>

            <Grid item xs={8} lg={8}>
              <Card sx={{ height: '100%' }}>
                <Box p={2.5}>
                  <Typography variant="h4">{click} by Gender and Age</Typography>
                </Box>
                <Divider />

                <BarChart
                  data={mapgendgerByAge() || []}
                  domain={['male', 'female']}
                  xDataKey="age_range"
                  labels={{ x: 'Male', y: 'Female' }}
                  dimensions={{ height: 270, width: '100%' }}
                  hideXAxis
                  hideYAxis
                  stack={isstack}
                  uppercaseLegend
                />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </FormikProvider>
    </>
  );
}
