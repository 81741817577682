import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HeaderFilters } from 'types/filters/filters';
import { FiltersDataSlice } from './types';


const initialState: FiltersDataSlice = {
  loaddata: null,
  loading: true,
  country: 'All',
  city: 'All',
  store: 'All',
  filterChangesApplied: null
};

export const filtersDataSlice = createSlice({
  name: 'filters-data',
  initialState,
  reducers: {
    processGetOverviewData: state => {
      state.loading = true;
    },
    getloadDataSuccess: (state, action: PayloadAction<HeaderFilters>) => {
      state.loaddata = action.payload;
      state.loading = false;
    },
    getloadDataFailure: state => {
      state.loading = false;
    },
    getCountryHeaderFilterDataSuccess: (state, action: PayloadAction<string>)=>{
      state.country = action.payload;
    },
    getCityHeaderFilterDataSuccess: (state, action: PayloadAction<string>)=>{
      state.city = action.payload;
    },
    getStoreHeaderFilterDataSuccess: (state, action: PayloadAction<string>)=>{
      state.store = action.payload;
    },
    getFilterChangesApplied:(state, action: PayloadAction<Date>) =>{
      state.filterChangesApplied = action.payload;
    }
  },
});
