import { onboardingApi } from 'api/api';
import { DashboardMenuItems } from 'types/DashboardMenu/dashboardMenu';

const getDashboardMenu = (params: string) =>
onboardingApi.get<DashboardMenuItems>(`/DashboardMenu/${params}`);


export const dashboardMenuApi = {
    getDashboardMenu
};
  