import { tiktokApi } from 'api/tiktok';
import { SelectedFeatures , OrganicSelectedFeatures} from 'types/connectors/facebook-data';
import { GetSystemAccessIdTiktokParams } from 'types/connectors/tiktok';

export const getInstallLink = () => async () => {
  const { data } = await tiktokApi.getInstallLink();
  return data.result;
};

export const getAdAccount = (systemAccessId: string) => async () => {
  const { data } = await tiktokApi.getAdAccount(systemAccessId);

  return data.result.map((d: any) => ({ id: d.id, value: d.name }));
};

export const getSystemAccessId = (params: GetSystemAccessIdTiktokParams) => async () => {
  const { data } = await tiktokApi.getSystemAccessId(params);

  return data.result;
};

export const saveSelection =
  (clientId: string, clientBrandId: string, clientBrandStoreId: string, selectedFeatures: SelectedFeatures) =>
  async () => {
    const { data } = await tiktokApi.saveSelection(clientId, clientBrandId, clientBrandStoreId, selectedFeatures);

    return data.result;
  };

  export const saveOrganicSelection =
  (clientId: string, clientBrandId: string, clientBrandStoreId: string, organicSelectedFeatures: OrganicSelectedFeatures) =>
  async () => {
    const { data } = await tiktokApi.saveOrganicSelection(clientId, clientBrandId, clientBrandStoreId, organicSelectedFeatures);

    return data.result;
  };

export const getOrganicInstallLink = () => async () => {
  const { data } = await tiktokApi.getOrganicInstallLink();
  return data.result;
};

export const getOrganicAccount = (systemAccessId: string) => async () => {
  const { data } = await tiktokApi.getOrganicAccount(systemAccessId);

  return data.result.map((d: any) => ({ id: d.id, value: d.name }));
};

export const getOrganicSystemAccessId = (params: GetSystemAccessIdTiktokParams) => async () => {
  const { data } = await tiktokApi.getOrganicSystemAccessId(params);

  return data.result;
};
