import { AppDispatch } from 'store/store';

import { dashboardMenuSlice} from './slice';
import { dashboardMenuApi } from 'api/DashboardMenu';

export const getDashboardMenuItems = (clientId: string, onFailure: () => void) => async (dispatch: AppDispatch) => {
  dispatch(dashboardMenuSlice.actions.processGetDashboardMenu());
  try {
    const { data } = await dashboardMenuApi.getDashboardMenu(clientId);
    
    dispatch(dashboardMenuSlice.actions.getDashboardMenuSuccess(data.result));
  } catch {
    dispatch(dashboardMenuSlice.actions.getDashboardMenuFailure());
    onFailure();
  }
};
