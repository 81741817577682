import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import {
  Button,
  Badge,
  Collapse,
  ListItem,
  Popover,
  Box,
  List,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  styled,
} from '@mui/material';
import clsx from 'clsx';
import { useDispatch } from 'hooks';
import { FC, MouseEvent, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { sidebarSlice } from 'store/entities/sidebar';

import { MenuItem } from './SidebarMenuItems';

interface SidebarMenuSubItemProps {
  item: MenuItem;
  onClick: () => void;
  maximized?: boolean;
  disabled?: boolean;
  tooltip?: string;
}

const SidebarMenuSubItem: FC<SidebarMenuSubItemProps> = ({
  item,
  onClick,
  maximized,
  disabled = false,
  tooltip = '',
}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    onClick();
    dispatch(sidebarSlice.actions.close());
  };

  return (
    <ListItem disablePadding>
      <Button
        disabled={disabled}
        fullWidth
        component={RouterLink as any}
        onClick={handleClick}
        to={item.newTab ? '' : item.link}
        startIcon={item.icon && <item.icon />}
      >
        {item.name}
        {maximized && item.badge && <Badge badgeContent={item.badge} />}
      </Button>
    </ListItem>
  );
};

interface SidebarMenuItemProps {
  open?: boolean;
  item: MenuItem;
  maximized?: boolean;
  disabled?: boolean;
  tooltip?: string;
  onClick?: () => void; // Add the optional onClick prop
  handleVideoSet?: () => void;
}

export const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  item,
  open: openParent,
  maximized,
  disabled = false,
  tooltip = '',
  handleVideoSet,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [menuToggle, setMenuToggle] = useState(openParent);

  const dispatch = useDispatch();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (item.newTab && item.link) {
      window.open(item.link, '_blank'); // Open the link in a new tab
    } else {
      maximized ? toggleMenu() : setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseSidebar = () => dispatch(sidebarSlice.actions.close());

  const toggleMenu = () => setMenuToggle(open => !open);

  const handleClosePopover = () => setAnchorEl(null);

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip style={{ marginLeft: 22 }} {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      marginLeft: '10px !important', // Add !important to force the margin-left
    },
  }));

  const renderSubItemList = () => (
    <List>
      {item.children.map(child => (
        <SidebarMenuSubItem key={child.name} item={child} onClick={handleClosePopover} maximized={maximized} />
      ))}
    </List>
  );

  const popoverOpen = Boolean(anchorEl);
  const id = open ? `popover-${item.name}` : undefined;

  const renderButton = () => (
    <Button
      fullWidth
      style={{ marginLeft: '5px' }}
      component={!disabled ? (RouterLink as any) : undefined}
      onClick={disabled ? handleVideoSet : handleClick}
      to={item.newTab ? '' : item.link}
      startIcon={item.icon && <item.icon />}
      sx={{
        opacity: disabled ? 0.5 : 1, // Set opacity to 0.5 when disabled
        filter: disabled ? 'grayscale(100%)' : 'none', // Apply grayscale filter when disabled
      }}
    >
      {maximized && item.name}
      {maximized && item.badge && <Badge badgeContent={item.badge} />}
    </Button>
  );
  const renderTooltipButton = () => <HtmlTooltip title={tooltip}>{renderButton()}</HtmlTooltip>;

  if (item.children) {
    return (
      <ListItem component="div" className="Mui-children">
        <Popover
          id={id}
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Box>{renderSubItemList()}</Box>
        </Popover>

        <Button
          aria-describedby={id}
          className={clsx({ active: menuToggle })}
          startIcon={item.icon && <item.icon />}
          endIcon={maximized ? menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon /> : null}
          onClick={handleClick}
        >
          {maximized && item.name}
        </Button>

        {maximized && <Collapse in={menuToggle}>{renderSubItemList()}</Collapse>}
      </ListItem>
    );
  }

  return <ListItem component="div">{disabled ? renderTooltipButton() : renderButton()}</ListItem>;
};
