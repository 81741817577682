import { Box, Card, CircularProgress, Container, Divider, Grid, Typography } from '@mui/material';
import { LineChart, BarChart, DoughnutChart, AreaLineChart, BarLineChart } from 'components/charts';
import { EmptyState } from 'components/empty-state';
import { Heading } from 'components/heading';
import { StatisticsCard } from 'components/statistics-card/statistics-card';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch, useToast, useSelector } from 'hooks';
import moment from 'moment';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { dashboardsSelectors, dashboardsSlice } from 'store/entities/dashboards';
import { marketingAnalyzerSelectors } from 'store/entities/marketing-analyzer';
import { getShopifyDashBoardData } from 'store/entities/shopify/actions';
import { userSelectors } from 'store/entities/user';
import { concatShopifyChartDataSets } from 'utils/concat-chart-data-sets';
import { dateFormmating } from 'utils/dateFormatting';
import { formatNumber, currencyLabel } from 'utils/numbers';

import { ShopifyLeadTable } from './components/dashboard-table/shopify-dashboard-table';
import { ShopifyDashboardFiltersForm } from './components/shopify-dashboard-filter-form/shopify-dashboard-filters-form';
import { StatisticsSection } from './components/statistics-section';

interface LinaChartData {
  period: string;
  name?: string;
  value: number;
}
export default function ShopifySalesDashboard() {
  const ecommData = useSelector(state => state.shopifySlice.dashboardData);
  const user = useSelector(userSelectors.user);
  const loading = useSelector(state => state.shopifySlice.loading);

  //Filter Handles
  const countryFilterValue = useSelector(state => state.filtersDataSlice.country);
  const cityFilterValue = useSelector(state => state.filtersDataSlice.city);
  const storeFilterValue = useSelector(state => state.filtersDataSlice.store);
  const filterChangesApplied = useSelector(state => state.filtersDataSlice.filterChangesApplied);
  
  const dispatch = useDispatch();
  const { errorToast } = useToast();

  const getmappedcancellationreasonSource = () => {
    const array = [];

    for (const [key, value] of Object.entries(ecommData?.shopify_ecomm_sales?.topCancellationReasons)) {
      array.push({ name: value.cancellation_reason, value: value.value });
    }
    return array;
  };

  const renderLineChart = (data: LinaChartData[], tooltipSymbol: string = '') => (
    <LineChart
      data={data || []}
      xDataKey="period"
      yDataKey="value"
      groupBy="name"
      dimensions={{ height: 120, width: '100%' }}
      hideLegend
      hideYAxis
      dashArray={['4 4', null]}
      tooltipAfterSymbol={tooltipSymbol}
      showDate={false}
    />
  );

  const renderNoData = (renderText: boolean = true) => (
    <Box
      minHeight={renderText ? '120px' : '130px'}
      height={renderText ? '120px' : '130px'}
      borderColor="red"
      borderBottom="1"
      borderTop="1"
    >
      <Typography variant="h6" height="100%" mt={4}>
        {renderText ? 'No Data' : ''}
      </Typography>
    </Box>
  );

  const date = useSelector(dashboardsSelectors.date);
  const endDate = useSelector(dashboardsSelectors.endDate);
  const compareStartDate = useSelector(dashboardsSelectors.compareStartDate);
  const compareEndDate = useSelector(dashboardsSelectors.compareEndDate);
  const marketsValue = useSelector(dashboardsSelectors.markets);
  const { clientId, brandList, brandStoreList } = useSelector(userSelectors.user);

  const initialFilters = useSelector(marketingAnalyzerSelectors.filters);

  const form = useFormik({
    initialValues: {
      ...initialFilters,
      date,
      markets: marketsValue,
    },
    onSubmit: values => {
      //dispatch(dashboardsSlice.actions.setDashboardsDate(values.date));

      if(date > endDate) {
        errorToast('Start date is after end date!');
        return;
      }

      dispatch(
        getShopifyDashBoardData(
          {
            StartDate: dateFormmating(date, true),
            EndDate: dateFormmating(endDate),
            CompareEndDate: dateFormmating(compareEndDate),
            CompareStartDate: dateFormmating(compareStartDate, true),
            TenantGUID: user.clientIdData,
            BrandGUID: brandList[0].Id,
            StoreId: '00000000-0000-0000-0000-000000000000', //pass empty guid and the api treats it as null to pass through to the sproc. Eventually this needs to send through storeIdentifier
            Country:countryFilterValue,
            City:cityFilterValue,
            Store:storeFilterValue

          },
          errorToast,
        ),
      );
    },
  });
  const { values, setFieldValue } = form;

  useEffect(() => {
    const currentDate = moment().startOf('day');
    const currentDateAsString = currentDate.format('YYYY-MM-DD HH:mm:ss');

    dispatch(
      getShopifyDashBoardData(
        {
          StartDate: dateFormmating(date, true),
          EndDate: dateFormmating(endDate),
          CompareEndDate: dateFormmating(compareEndDate),
          CompareStartDate: dateFormmating(compareStartDate, true),
          TenantGUID: user.clientIdData,
          BrandGUID: brandList[0].Id,
          StoreId: '00000000-0000-0000-0000-000000000000', //pass empty guid and the api treats it as null to pass through to the sproc. Eventually this needs to send through storeIdentifier
          Country:countryFilterValue,
          City:cityFilterValue,
          Store:storeFilterValue

        },
        errorToast,
      ),
    );
  }, [filterChangesApplied]);

  return (
    <>
      <Helmet>
        <title>Point of Sales / E-Commerce Sales</title>
      </Helmet>

      <FormikProvider value={form}>
        <Container maxWidth="xl" id="shopify-container">
          <Grid container direction="row" justifyContent="left" alignItems="start" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h1">Point of Sales / E-Commerce Sales</Typography>
            </Grid>

            <Grid item lg={12} xs={12}>
              <ShopifyDashboardFiltersForm />
            </Grid>

            {loading ? (
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              </Grid>
            ) : !ecommData.shopify_ecomm_sales ? (
              <Grid item xs={12}>
                <Card>
                  <EmptyState>There is no data</EmptyState>
                </Card>
              </Grid>
            ) : (
              <>
                {/* Primary KPIs */}
                {ecommData?.shopify_ecomm_sales?.statistics ? (
                  <StatisticsSection data={ecommData?.shopify_ecomm_sales?.statistics[0]} />
                ) : (
                  <Grid item xs={12}>
                    <Card>
                      <EmptyState>There is no statistics data</EmptyState>
                    </Card>
                  </Grid>
                )}

                {/* Secondary KPIs */}
                <Grid container item xs={12} lg={12} spacing={2}>
                  <Grid item xs={1} />
                  {ecommData?.shopify_ecomm_sales?.statistics ? (
                    <Grid item xs={2} lg={2}>
                      <StatisticsCard
                        sx={{ minHeight: '233px' }}
                        name={'Orders'}
                        tooltip="The number of sales orders"
                        value={`${formatNumber(ecommData?.shopify_ecomm_sales?.statistics[0]?.orders?.value, 0)}`}
                        description={`Compare To: ${formatNumber(
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.orders?.prevValue,
                          0,
                        )}\n(${formatNumber(
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.orders?.prevValueCompare,
                          1,
                        )}%)`}
                        variant={
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.orders?.prevValueCompare >= 0
                            ? 'positive'
                            : 'negative'
                        }
                        customChart={
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.orders.prevDataset != null &&
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.orders.dataSet != null
                            ? renderLineChart(
                                concatShopifyChartDataSets(
                                  ecommData?.shopify_ecomm_sales?.statistics[0]?.orders?.prevDataset,
                                  ecommData?.shopify_ecomm_sales?.statistics[0]?.orders?.dataSet,
                                ),
                              )
                            : renderNoData()
                        }
                      />
                    </Grid>
                  ) : (
                    <Grid item lg={3} xs={12}>
                      <Card>
                        <EmptyState>There is no statistics data</EmptyState>
                      </Card>
                    </Grid>
                  )}
                  {ecommData?.shopify_ecomm_sales?.statistics ? (
                    <Grid item xs={2} lg={2}>
                      <StatisticsCard
                        sx={{ minHeight: '233px' }}
                        name={'New Customers'}
                        tooltip="The number of customers who placed their first order with you in the selected period"
                        value={`${formatNumber(
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.new_customers?.value,
                          0,
                        )}`}
                        description={`Compare To: ${formatNumber(
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.new_customers?.prevValue,
                          0,
                        )}\n(${formatNumber(
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.new_customers?.prevValueCompare,
                          1,
                        )}%)`}
                        variant={
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.new_customers?.prevValueCompare >= 0
                            ? 'positive'
                            : 'negative'
                        }
                        customChart={
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.new_customers?.prevDataset != null &&
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.new_customers?.dataSet != null
                            ? renderLineChart(
                                concatShopifyChartDataSets(
                                  ecommData?.shopify_ecomm_sales?.statistics[0]?.new_customers?.prevDataset,
                                  ecommData?.shopify_ecomm_sales?.statistics[0]?.new_customers?.dataSet,
                                ),
                              )
                            : renderNoData()
                        }
                      />
                    </Grid>
                  ) : (
                    <Grid item lg={3} xs={12}>
                      <Card>
                        <EmptyState>There is no statistics data</EmptyState>
                      </Card>
                    </Grid>
                  )}

                  {ecommData?.shopify_ecomm_sales?.statistics[0]?.avg_orders_per_day ? (
                    <Grid item lg={2} xs={2}>
                      <StatisticsCard
                        sx={{ minHeight: '233px' }}
                        name={'Avg. Orders Per Day'}
                        tooltip="The average number of Sales Orders in the period"
                        value={`${formatNumber(
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.avg_orders_per_day?.value,
                          0,
                        )}`}
                        description={`Compare To: ${formatNumber(
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.avg_orders_per_day?.prevValue,
                          0,
                        )}\n(${formatNumber(
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.avg_orders_per_day?.prevValueCompare,
                          1,
                        )}%)`}
                        variant={
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.avg_orders_per_day?.prevValueCompare >= 0
                            ? 'positive'
                            : 'negative'
                        }
                        customChart={
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.avg_orders_per_day?.prevDataset != null &&
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.avg_orders_per_day?.dataSet != null
                            ? renderLineChart(
                                concatShopifyChartDataSets(
                                  ecommData?.shopify_ecomm_sales?.statistics[0]?.avg_orders_per_day?.prevDataset,
                                  ecommData?.shopify_ecomm_sales?.statistics[0]?.avg_orders_per_day?.dataSet,
                                ),
                              )
                            : renderNoData()
                        }
                      />
                    </Grid>
                  ) : (
                    <Grid item lg={3} xs={12}>
                      <Card>
                        <EmptyState>There is no avg orders per day data</EmptyState>
                      </Card>
                    </Grid>
                  )}

                  {ecommData?.shopify_ecomm_sales?.statistics[0]?.avg_orders_per_day ? (
                    <Grid item lg={2} xs={2}>
                      <StatisticsCard
                        sx={{ minHeight: '233px' }}
                        name={'Avg. Days to Fulfil an Order'}
                        tooltip="The average number of days to dispatch an order"
                        value={`${formatNumber(
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.avg_time_to_fulfil.value,
                          0,
                        )}`}
                        variant={
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.avg_time_to_fulfil.value >= 0
                            ? 'positive'
                            : 'negative'
                        }
                        description={''}
                        customChart={renderNoData(false)}
                      />
                    </Grid>
                  ) : (
                    <Grid item lg={3} xs={12}>
                      <Card>
                        <EmptyState>There is no avg orders per day data</EmptyState>
                      </Card>
                    </Grid>
                  )}

                  {/* {ecommData?.shopify_ecomm_sales?.statistics[0]?.total_cancelled_orders ? (
                    <Grid item lg={2} xs={2}>
                      <StatisticsCard
                        sx={{ minHeight: '233px' }}
                        name={'all cancelled orders'}
                        tooltip='The average number of sales orders that were cancelled per day'
                        value={`${formatNumber(
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.total_cancelled_orders?.value,
                          0,
                        )}`}
                        variant={
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.total_cancelled_orders?.value >= 0
                            ? 'positive'
                            : 'negative'
                        }
                        description={''}
                        customChart={renderNoData(false)}
                      />
                    </Grid>
                  ) : (
                    <Grid item lg={3} xs={12}>
                      <Card>
                        <EmptyState>There is no avg. cancelled orders per day data</EmptyState>
                      </Card>
                    </Grid>
                  )} */}

                  {ecommData?.shopify_ecomm_sales?.statistics[0]?.total_cancelled_orders ? (
                    <Grid item lg={2} xs={2}>
                      <StatisticsCard
                        sx={{ minHeight: '233px' }}
                        name={'Avg. # Cancelled Orders Per Day'}
                        tooltip="The average number of sales orders that were cancelled per day"
                        value={`${formatNumber(
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.avg_cancelled_orders_per_day?.value,
                          0,
                        )}`}
                        variant={
                          ecommData?.shopify_ecomm_sales?.statistics[0]?.avg_cancelled_orders_per_day?.value >= 0
                            ? 'positive'
                            : 'negative'
                        }
                        description={''}
                        customChart={renderNoData(false)}
                      />
                    </Grid>
                  ) : (
                    <Grid item lg={3} xs={12}>
                      <Card>
                        <EmptyState>There is no avg. cancelled orders per day data</EmptyState>
                      </Card>
                    </Grid>
                  )}
                </Grid>

                {/* Top 10 Products section */}
                {ecommData?.shopify_ecomm_sales?.topSellingProducts != null ? (
                  <Grid item xs={12}>
                    <Heading>Top 10 Selling Lifetime Products</Heading>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Card>
                      <EmptyState>There is no top Selling Products data</EmptyState>
                    </Card>
                  </Grid>
                )}

                {ecommData?.shopify_ecomm_sales?.topSellingProducts ? (
                  <Grid item lg={12} xs={12}>
                    <Card style={{ border: '2px solid #f0f1f6' }}>
                      {ecommData?.shopify_ecomm_sales?.topSellingProducts?.length > 0 ? (
                        <ShopifyLeadTable
                          currencyLabel={currencyLabel(user.brandStoreList[0].StoreCurrency)}
                          columns={[
                            'Product Name',
                            'Sales',
                            'Orders',
                            'Avg. Sales Per Week',
                            'Avg. Sales Per Month',

                            'Returns Units',
                            'Days on Market',
                          ]}
                          data={ecommData?.shopify_ecomm_sales?.topSellingProducts ?? []}
                        />
                      ) : (
                        <EmptyState> There is no top Selling Products date</EmptyState>
                      )}
                    </Card>
                  </Grid>
                ) : (
                  <Grid item lg={12} xs={12}>
                    <Card>
                      <EmptyState>There is no Cancellation Reasons data</EmptyState>
                    </Card>
                  </Grid>
                )}
              </>
            )}

            {/* 

  

         

          */}
          </Grid>
        </Container>
      </FormikProvider>
    </>
  );
}
