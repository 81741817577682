import { Response } from 'types/api/api';
import { FeatureData } from 'types/connectors/connectors';
import { SelectedFeatures, OrganicSelectedFeatures } from 'types/connectors/facebook-data';
import { GetSystemAccessIdTiktokParams } from 'types/connectors/tiktok';
import { AudienceOverview, AudienceOverviewResponseData, TiktokDashboardRequest } from 'types/Tiktok/Tiktok';

import { onboardingApi, vennFlowApi } from '../api';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getInstallLink = () =>
  onboardingApi.get(`TikTokConnector/AuthUrl?redirectUrl=${process.env.REACT_APP_TIKTOK_REDIRECT_URL}`);

const getSystemAccessId = (params: GetSystemAccessIdTiktokParams) =>
  onboardingApi.get(`TikTokConnector/ExchangeAccessCodeForAccessToken/${params.accessCode}`, {
    params: {
      clientBrandId: params.clientBrandId,
      clientBrandStoreId: params.clientBrandStoreId,
      redirectUrl: params.redirectUrl,
      clientId: params.clientId,
    },
  });

const getAdAccount = (systemAccessId: string) =>
  onboardingApi.get<Response<FeatureData[]>>(`TikTokConnector/adAccounts/${systemAccessId}`);

// TODO update types below
const saveSelection = (
  clientId: string,
  clientBrandId: string,
  clientBrandStoreId: string,
  selectedFeatures: SelectedFeatures,
) =>
  onboardingApi.post<Response<FeatureData[]>>('TikTokConnector/AnalyticsFeatures', {
    clientId,
    systemId: '0E393BDF-7D35-435B-9BE4-ED9B89F8E35A',
    clientBrandId,
    clientBrandStoreId,
    features: [
      {
        id: 'advertiserId',
        value: selectedFeatures.adAccount?.id,
      },
    ],
  });

const saveOrganicSelection = (
  clientId: string,
  clientBrandId: string,
  clientBrandStoreId: string,
  organicSelectedFeatures: OrganicSelectedFeatures,
) =>
  onboardingApi.post<Response<FeatureData[]>>('TikTokConnector/AnalyticsFeatures', {
    clientId,
    systemId: '50C4D966-C91C-4D61-B9A3-E401F0C98C2A',
    clientBrandId,
    clientBrandStoreId,
    features: [
      {
        id: 'creator_id',
        value: organicSelectedFeatures.userId?.id,
      },
    ],
  });

// Get Audience Overview from endpoint
const getTiktokDashboard = (params: TiktokDashboardRequest) =>
  //vennFlowApi.get<Response<AudienceOverviewResponseData[]>>(`TiktokDashboard/TiktokDashboard?DaysFilter=${daysFilter}&Date=${new Date(date).toISOString().split('T')[0]}`);
  vennFlowApi.get<{ audienceOverview: AudienceOverview }>('TitokDashboard/TiktokDashboard', { params });

// Utility Functions for PKCE
const generateCodeVerifier = () => {
  const randomString = Array.from(crypto.getRandomValues(new Uint8Array(32)))
    .map(byte => ('0' + byte.toString(16)).slice(-2))
    .join('');
  return randomString;
};

const generateCodeChallenge = async (verifier: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(verifier);
  const hash = await crypto.subtle.digest('SHA-256', data);
  // Avoid using the spread operator with Uint8Array
  const hashArray = new Uint8Array(hash);
  let hashString = '';
  for (let i = 0; i < hashArray.length; i++) {
    hashString += String.fromCharCode(hashArray[i]);
  }
  return btoa(hashString).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

// PKCE: Get Organic Install Link
// const getOrganicInstallLink = async () => {
//   // Store the code verifier for later use
//   const response = await onboardingApi.get(
//     `TikTokConnector/OrganicAuthUrl?redirectUrl=${process.env.REACT_APP_TIKTOK_ORGANIC_REDIRECT_URL}`,
//   );
//   return response;
// };

// PKCE: Get Organic Install Link
const getOrganicInstallLink = async () => {
  const codeVerifier = generateCodeVerifier();
  const codeChallenge = await generateCodeChallenge(codeVerifier);

  // Store the code verifier for later use
  localStorage.setItem('tiktok_organic_code_verifier', codeVerifier);

  const response = await onboardingApi.get(
    `TikTokConnector/OrganicAuthUrlWithPKCE?redirectUrl=${process.env.REACT_APP_TIKTOK_ORGANIC_REDIRECT_URL}&code_challenge=${codeChallenge}&code_challenge_method=S256`,
  );
  return response;
};

const getOrganicSystemAccessId = (params: GetSystemAccessIdTiktokParams) => {
  const codeVerifier = localStorage.getItem('tiktok_organic_code_verifier');
  if (!codeVerifier) {
    throw new Error('Missing PKCE code verifier');
  }
  return onboardingApi.get(
    `TikTokConnector/OrganicExchangeAccessCodeForAccessToken?accessCode=${params.accessCode}&codeVerifier=${codeVerifier}&redirectUrl=${params.redirectUrl}&clientId=${params.clientId}&clientBrandId=${params.clientBrandId}&clientBrandStoreId=${params.clientBrandStoreId}`,
  );
};

const getOrganicAccount = (systemAccessId: string) =>
  onboardingApi.get<Response<FeatureData[]>>(`TikTokConnector/OrganicAccounts/${systemAccessId}`);

export const tiktokApi = {
  getInstallLink,
  getAdAccount,
  getSystemAccessId,
  saveSelection,
  saveOrganicSelection,
  getTiktokDashboard,
  getOrganicInstallLink,
  getOrganicAccount,
  getOrganicSystemAccessId,
};
