import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import DonutSmallTwoToneIcon from '@mui/icons-material/DonutSmallTwoTone';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PsychologyIcon from '@mui/icons-material/Psychology';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

// Map string values to icons
const IconMap: { [key: string]:  OverridableComponent<SvgIconTypeMap<{}, 'svg'>> } = {
  ContactSupportIcon: ContactSupportIcon,
  DonutSmallTwoToneIcon: DonutSmallTwoToneIcon,
  InsertChartIcon: InsertChartIcon,
  LocalMallIcon: LocalMallIcon,
  ManageAccountsIcon: ManageAccountsIcon,
  ManageSearchIcon: ManageSearchIcon,
  PsychologyIcon: PsychologyIcon,
  QueryStatsIcon: QueryStatsIcon,
  RemoveRedEyeTwoToneIcon: RemoveRedEyeTwoToneIcon,
  SettingsTwoToneIcon: SettingsTwoToneIcon,
  ShoppingCartIcon: ShoppingCartIcon,
  ShowChartIcon: ShowChartIcon,
};

export default IconMap;
