import { ListSubheader, List } from '@mui/material';
import { VideoModal } from 'components/videoModal';
import { FC, useEffect, useState } from 'react';

import { SidebarMenuItem } from './SidebarMenuItemLayout';
import { MenuItemsProps, MenuItem } from './SidebarMenuItems';
import { MenuWrapper, SubMenuWrapper } from './styles';
import { useSelector } from 'hooks';
import { routes } from 'router/routes';
import IconMap from 'types/DashboardMenu/icons';

interface Props {
  maximized?: boolean;
  ismarketing?: boolean;
  companyName?: string;
}

export const SidebarMenu: FC<Props> = ({ maximized, ismarketing, companyName }) => {
  const [videoURL, setVideoURL] = useState('https://www.youtube.com/embed/qIWysveujLg?si=_WLEvE2LK8Cw2osW');
  const [showVideoModal, setShowVideoModal] = useState(false);
  const pyramidloaddata = useSelector(state => state.pyramidloaddataSlice.loaddata);
  const [dataLoaded, setDataLoaded] = useState(false); // State variable to track if data is loaded
  const hasPyramidToken = Boolean(localStorage.getItem('pyramidToken'));
  const hasPyramidEmbedToken = Boolean(localStorage.getItem('embedPyramidToken'));
  const [filteredMenuItems, setFilteredMenuItems] = useState<MenuItemsProps[]>([]); // State to hold filtered menu items

  const dashboardMenuItems = useSelector(state => state.dashboardMenuSlice.dashboardMenuItems);

// Only run this effect when pyramidloaddata changes
  useEffect(() => {
    if ((pyramidloaddata && pyramidloaddata.length > 0 && pyramidloaddata[0].accessToken) || hasPyramidToken) {
      setDataLoaded(true);
    } else {
      setDataLoaded(false);
    }
  }, [pyramidloaddata]); 

  const handleVideoModalClose = () => {
    setShowVideoModal(false);
  };

  const handleMenuSetVideo = (val: string) => {
    if(val !== undefined && val !== '')
    {
      setVideoURL(val);
      setShowVideoModal(true);
    }
  };

  const handleClick = (link: string, newTab?: boolean) => {
    if (link) {
      if (newTab) {
        // Open link in a new tab
        window.open(link, '_blank');
      } else {
        // Open link in the same tab
        window.location.href = link;
      }
    }
  };

  useEffect(() =>
  {
    if(dashboardMenuItems != undefined && dashboardMenuItems.length>0)
    {
      const items: MenuItemsProps[] = new Array();
      const menuItems:MenuItem[] = new Array();
      
      //clean out the menu
      setFilteredMenuItems([]);

      dashboardMenuItems.forEach(item => {
        if(item.mapping.showDashboard)
        {
          //MAYBE?? May need to change this depending on needed functionality, should mapping.showDashboard override 
          // the disabled functionality or anything like that
          
          //set menu item disable status based on mapping, then fallback to dashboards definition
          let disableMenuItem:boolean = item.mapping.disableDashboard ? true: item.dashboard.disabled;
          
          //if the dashboard is pyramid and there is 
          if((!hasPyramidToken || !hasPyramidEmbedToken) && item.dashboard.isPyramid) 
            disableMenuItem = true;
         
          menuItems.push({
            name: item.dashboard.displayName,
            icon: IconMap[item.dashboard.icon],
            link: item.dashboard.route, 
            disable: disableMenuItem,
            tooltip: item.dashboard.tooltipText,
            videoUrl: item.dashboard.videoURL,
            newTab:  item.dashboard.openNewTab
          })
        }
      })      

      items.push({
        name:'General',
        items: menuItems
      },
      {//default management menu items
        name: 'Management',
        items: [
          {
            name: 'Settings',
            icon: IconMap['SettingsTwoToneIcon'],
            link: routes.settings.url,
          },
          {
            name: 'Data Export',
            icon: IconMap['SettingsTwoToneIcon'],
            link: routes.dataExport.url,
          },
          {
            name: 'User Management',
            icon: IconMap['ManageAccountsIcon'],
            link: routes.userManagement.url,
          },
          {
            name: 'Support',
            icon: IconMap['ContactSupportIcon'],
            link: routes.support.url,
          },
        ]
      }
    );
      // set nav items
      setFilteredMenuItems(items)
    }
  },[dashboardMenuItems, hasPyramidToken, hasPyramidEmbedToken])

  return (
    <>
      {filteredMenuItems.map(section => (
        <MenuWrapper key={section.name}>
          <List
            component="div"
            subheader={
              maximized ? (
                <ListSubheader component="div" className="MuiListSubheader-root">
                  {section.name === 'General' ? companyName ?? 'General' : section.name}
                </ListSubheader>
              ) : null
            }
          >
            {section.items.map(item => (
              <SubMenuWrapper key={item.name}>
                <List>
                  <SidebarMenuItem
                    item={item}
                    disabled={item.disable}
                    tooltip={item.tooltip}
                    maximized={maximized}
                    handleVideoSet={() => handleMenuSetVideo(item.videoUrl)}
                    onClick={!item.disable ? () => handleClick(item.link, item.newTab) : undefined}
                  />
                </List>
              </SubMenuWrapper>
            ))}
          </List>
        </MenuWrapper>
      ))}

      {showVideoModal ? <VideoModal videoUrl={videoURL} handleClose={handleVideoModalClose} /> : <></>}
    </>
  );
};
