import { Typography, TableHead, TableRow, TableCell, Tooltip } from '@mui/material';
import { FC } from 'react';

interface Props {
  columns: string[];
}

const getToolTip = (col: string) => {
  switch (col.toLowerCase()) {
    case 'cost per impression (1000)':
      return 'The cost of delivering 1000 Ad impressions';
    case 'website conversions':
      return 'The number of Conversions attributed by Google';
    case 'cpc':
      return 'The cost of each Ad Click';
    case 'roas':
      return 'Return on Ad Spend';
    case 'cpl':
      return 'Cost per lead';
    case 'cpa':
      return 'Cost per Acquisition (conversion)';
    case 'impressions':
      return 'The number of times an Ad was seen';
    case 'ad spend':
      return 'The value spent on Ads';
    case 'sessions':
      return 'Unique website sessions';
    case 'leads':
      return 'A customer journey outcome classified as a sales lead in GA4, can include sales and uer defined goals';
    case 'ad clicks':
      return 'The number of times a link in an Ad was clicked';
    case 'reach':
      return 'The number of unique users that viewed an Ad';
    case 'engagement rate %':
      return 'The % of engaged sessions (sessions lasted > 10 seconds).';
    case 'engagements':
      return 'The number of interactions with an Ad: Likes, Comments, Profile visits';
    default:
      return '';
  }
};

const renderTooltip = (val: string) => {
  const toolTip = getToolTip(val);

  if (toolTip === '') {
    return <>{val}</>;
  } else {
    return (
      <Typography>
        <Tooltip title={toolTip}>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100',
              fontWeight: 'bold',
              fontSize: '15px',
            }}
          >
            {val}
          </div>
        </Tooltip>
      </Typography>
    );
  }
};

export const CompareTableHeader: FC<Props> = ({ columns }) => (
  <TableHead>
    <TableRow>
      {columns.map(column => (
        <TableCell key={column}>
          <Typography variant="h6" minHeight={30} textTransform={'initial'}>
            {renderTooltip(column)}
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);
