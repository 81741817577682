import { Box, MenuItem, Select, Typography } from '@mui/material';
import { select } from '@shopify/app-bridge/actions/ResourcePicker';
import { FC, useEffect } from 'react';
import { HeaderFilters } from 'types/filters/filters';

interface Props {
    title:string;
    data: HeaderFilters;
    type:string;
    selectedValue:string;
    onSelect:(e:any) => void;
    selectedParent?:string;
    selectedParentParent?:string;
}

export const FilterDropdown : FC<Props> = ({ title,
     data,
     type,
     selectedValue,
     onSelect,
     selectedParent,
     selectedParentParent}) => {


const renderSwitch = (type:string, data:HeaderFilters) => {
    if(data == null)
        return;

    switch(type) {
        case 'country':
            {
            return data.country.map((item:any) => (
                    <MenuItem key={item.name} value={item.name}>{item.name}</MenuItem> 
                ));
            }
        case 'city':
            {
                return data.city.map((item:any) => ( item.country == selectedParent ?
                    //filter on country selected
                    <MenuItem key={(item.country+'_'+ item.name)} value={item.name}>{item.name}</MenuItem> :<></>
                ));
            }
        case 'store':
            {
                return data.store.map((item:any) => ( item.country == selectedParentParent && item.city == selectedParent ?
                    //filter on country and city selected
                    <MenuItem key={(item.city +'_'+ item.name)} value={item.name}>{item.name}</MenuItem> :<></>
                ));
            }

    }
    }

  return (
    <>
    <Typography variant='h6' sx={{marginRight:'.25em'}}>
        {title}
    </Typography>
    <Box sx={{ mr: 1, mt: 1, marginTop:'-5px' }}>
        <Select
        label={title}
        value={selectedValue}
        sx={{
          width: '100%',
          textAlign: 'right',
          backgroundColor: '#fff',
          borderRadius: '15px',
        }}
        onChange={(e) => onSelect(e.target.value)}
      >
  {/* <MenuItem key={index} value={index}>{item.dailyLoads[index].systemName}{item.dailyLoads[index].lastSuccessRunDT!=null? new Date(item.dailyLoads[index].lastSuccessRunDT).toUTCString():''}</MenuItem> */}
         {renderSwitch(type, data)}
      </Select>
      <Box sx={{ mx: 0.5 }} component="span" />
    </Box>
    </>
  );
};
