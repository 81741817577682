import { DashboardMenuState } from './types';

const dashboardMenuItems = (state: DashboardMenuState) => state.dashboardMenu.dashboardMenuItems;

const loading = (state: DashboardMenuState) => state.dashboardMenu.loading;

export const dashboardMenuSelectors = {
  dashboardMenuItems,
  loading,
};
