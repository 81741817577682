import { FiltersDataState } from './types';

const loaddata = (state: FiltersDataState) => state.loaddata.loaddata;

const loading = (state: FiltersDataState) => state.loaddata.loading;

const countrySelection = (state: FiltersDataState) => state.loaddata.country
const citySelection = (state: FiltersDataState) => state.loaddata.city
const storeSelection = (state: FiltersDataState) => state.loaddata.store
const filterChangesAppliedSelection = (state:FiltersDataState) => state.loaddata.filterChangesApplied

export const filtersdataSelectors = {
  loaddata,
 loading,
 countrySelection,
 citySelection,
 storeSelection,
 filterChangesAppliedSelection
};
