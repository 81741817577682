import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DashboardMenuSlice } from './types';
import { DashboardMenuItem } from 'types/DashboardMenu/dashboardMenu';

const initialState: DashboardMenuSlice = {
  dashboardMenuItems: [],
  loading: true,
};

export const dashboardMenuSlice = createSlice({
  name: 'dashboardmenu',
  initialState,
  reducers: {
    processGetDashboardMenu: state => {
      state.loading = true;
    },
    getDashboardMenuSuccess: (state, action: PayloadAction<DashboardMenuItem[]>) => {
      state.dashboardMenuItems = action.payload;
    },
    getDashboardMenuFailure: state => {
      state.loading = false;
    },
  },
});
