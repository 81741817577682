import { AppDispatch } from 'store/store';
import { filtersDataSlice } from './slice';
import { filtersApi } from 'api/Filters';

export const getFiltersStateData =
  (clientId: string, onFailure: () => void) => async (dispatch: AppDispatch) => {
    dispatch(filtersDataSlice.actions.processGetOverviewData());

    try {
      const { data } = await filtersApi.getHeaderFilters(clientId);

      dispatch(filtersDataSlice.actions.getloadDataSuccess(data));
    } catch {
      dispatch(filtersDataSlice.actions.getloadDataFailure());

      onFailure();
    }
  };

export const SetCountryFilterData =(val: string, onFailure: () => void) => async (dispatch: AppDispatch)=> {
  try
  {
    dispatch(filtersDataSlice.actions.getCountryHeaderFilterDataSuccess(val));
  }catch{
    onFailure();
  }
}

  
export const SetCityFilterData =(val: string, onFailure: () => void) => async (dispatch: AppDispatch)=> {
  try
  {
    dispatch(filtersDataSlice.actions.getCityHeaderFilterDataSuccess(val));
  }catch{
    onFailure();
  }
}


export const SetStoreFilterData =(val: string, onFailure: () => void) => async (dispatch: AppDispatch)=> {
  try
  {
    dispatch(filtersDataSlice.actions.getStoreHeaderFilterDataSuccess(val));
  } catch {
    onFailure();
  }
}

export const SetFilterChangesApplied =(val: Date, onFailure: () => void) => async (dispatch: AppDispatch)=> {
  try
  {
    dispatch(filtersDataSlice.actions.getFilterChangesApplied(val));
  } catch {
    onFailure();
  }
}

